/**
 * Getters
 *
 * Vuex allows us to define "getters" in the store.
 * - You can think of them as computed properties for stores.
 * - Like computed properties, a getter's result is cached based on its dependencies,
 *   and will only re-evaluate when some of its dependencies have changed.
 */
import { GetterTree } from 'vuex';
import { RootState } from '../types';
import { PropertyVisitManagementState } from './types';
import { Booking, PmTenancy } from '@/models';

export const getters: GetterTree<PropertyVisitManagementState, RootState> = {

  /**
   * Are the tenancies Empty?
   * 
   * @param {PropertyVisitManagementState} state 
   * @returns Boolean if tenancy is empty
   */
  isEmpty: (state: PropertyVisitManagementState): boolean => {
    return state.tenancies.length === 0;
  },

  /**
   * Get the list of tenancies
   * 
   * @param state PropertyVisitManagementState
   * @returns tenancies list
   */
  getTenancies: (state: PropertyVisitManagementState): PmTenancy[] => {
    return state.tenancies;
  },
   /**
   * Get the list of tenancies
   * 
   * @param state PropertyVisitManagementState
   * @returns tenancies list
   */
   getBookingsData: (state: PropertyVisitManagementState): Booking[] => {
    return state.list;
  },

  /**
   * Get the current tenancy
   * 
   * @param {PropertyVisitManagementState} state 
   * @returns Current tenancies
   */
  currentTenancy: (state: PropertyVisitManagementState): PmTenancy | undefined => {
    return state.tenancies.find(x => x.id === state.currentTenancyId);
  },
  list: (state: PropertyVisitManagementState): Booking[] => state.list,
};
