import { Module } from "vuex";
import { RootState } from "../types";
import { PvBookingsState } from "./types";

import { getters } from "./getters";
import { actions } from "./actions";
import { mutations } from "./mutations";
import { Booking } from "@/models";

/**
 * Default state
 */
export const state: PvBookingsState = {
  booking: new Booking(),
  list: [],
  updates: [],
  currentdate: new Date(),
  customerlist: [],
};

/**
 * Use namespaced Vuex modules
 */
const namespaced: boolean = true;

/**
 * Module export
 */
const diary: Module<PvBookingsState, RootState> = {
  state,
  getters,
  mutations,
  actions,
  namespaced,
};

export default diary;
