import { Module } from 'vuex';
import { RootState } from '../types';
import { PropertyVisitManagementState } from './types';

import { getters } from './getters';
import { actions } from './actions';
import { mutations } from './mutations';

// Default state
export const state: PropertyVisitManagementState = {
  currentTenancyId: '',
  tenancies: [],
  list: [],
  currentPage: 1,
  pageLimit: 20,
  totalcount: 0,
};

// Use namespaced Vuex modules
const namespaced: boolean = true;

// Module export
const tenancies: Module<PropertyVisitManagementState, RootState> = {
  state,
  getters,
  mutations,
  actions,
  namespaced,
};

export default tenancies;
