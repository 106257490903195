import _ from "lodash";
import _set from "lodash/set";
import moment from "moment-timezone";

import Vue from "vue";

import _snakeCase from "lodash/snakeCase";

import { MutationTree } from "vuex";
import { PvBookingsState } from "./types";
import {
  Customer,
  Booking,
} from "@/models";
/**
 * Mutations
 *
 * The only way to actually change state in a Vuex store is by committing a mutation.
 * - Vuex mutations are very similar to events: each mutation has a string type and a handler.
 * - The handler function is where we perform actual state modifications - it receives the state as the first argument:
 * - You cannot directly call a mutation handler.
 * - Think of it more like event registration: "When a mutation with type X is triggered, call this handler."
 * - To invoke a mutation handler, you need to call store.commit with its type
 * - e.g. `store.commit('setInspectors', inspector)`
 */
export const mutations: MutationTree<PvBookingsState> = {
  setBooking(state: PvBookingsState, booking: Booking): void {
    const bookingstartdateMoment = moment(booking.startdateAsDate);
    const currentdateMoment = moment(state.currentdate);
    if (
      booking.subtype != Booking.PERSONAL ||
      bookingstartdateMoment.isSame(currentdateMoment, "day")
    ) {
      state.booking = booking;
      state.currentdate = moment(booking.startdate)
        .utc()
        .startOf("day")
        .add(9, "hours")
        .toDate();
    }
  },
  setBookings(state: PvBookingsState, list: Booking[]): void {
    state.list = list;
  },
  setCustomers(state: PvBookingsState, customers: Customer[]): void {
    state.customerlist = customers;
  },
};
