<template>
  <div class="customer">
    <div class="row">
      <div class="col-md-6">
        <h1>Property Visit Management</h1>
      </div>
      <div class="col-sm-6 text-right">
        <label>
          <span class="btn btn-sm btn-outline-secondary document-upload-button">
            <div><i class="fas fa-file-upload"></i> XLSX File</div>
          </span>
          <input type="file" accept=".xlsx" ref="file" v-on:change="onFileChange" />
        </label>
      </div>
    </div>
  </div>
  <PropertyVisitManagementsList />
</template>

<script lang="ts" setup>
import { Address, Booking, Customer, Tenant } from "@/models";
import { useStore } from "vuex";
import * as XLSX from "xlsx";
import _ from "lodash";
import PropertyVisitManagementsList from "@/components/propertyvisitmanagement/PropertyVisitManagementsList.vue";
import { useToast } from "vue-toastification";
import { computed, defineProps, inject, ref } from "vue";
import moment from "moment";
const store = useStore();
const file = ref(null);
const actProperty: any = inject("actProperty");
const toasted = useToast();
const customerlist = computed(
  (): Customer[] => store.getters["pvbookings/customerlist"]
);
const addBookings = (bookings: Booking[]): Promise<Booking[]> => {
  return store.dispatch("pvbookings/addPvBookings", bookings);
};
// Define a ref to hold file data
const fileData = ref<Array<Record<string, any>> | null>(null);
const filename = ref("");

async function onFileChange(event: Event) {
  const target = event.target as HTMLInputElement;
  if (target.files && target.files.length > 0) {
    const file = target.files[0];
    filename.value = file.name;

    const reader = new FileReader();

    reader.onload = async (e) => {
      const data = new Uint8Array(e.target?.result as ArrayBuffer);
      const workbook = XLSX.read(data, { type: "array" });

      // Get the first sheet and convert it to an array of objects
      const worksheet = workbook.Sheets[workbook.SheetNames[0]];
      fileData.value = XLSX.utils.sheet_to_json(worksheet, { header: 1 });

      // Use header row as keys and convert each row into an object
      const headers = fileData.value[0];
      const rows = fileData.value.slice(1);
      // Map rows to objects and filter out blank objects
      fileData.value = rows
        .map((row) =>
          headers.reduce((acc, header, i) => {
            acc[header] = row[i];
            return acc;
          }, {} as Record<string, any>)
        )
        .filter((obj) =>
          Object.values(obj).some(
            (value) => value !== null && value !== undefined && value !== ""
          )
        );
      fileData.value = fileData.value.map((item) => ({
        ...item,
        "Due Date":
          typeof item["Due Date"] === "number"
            ? excelDateToJSDate(item["Due Date"])
            : item["Due Date"],
        Property: parseAddress(item["Property"], item["Postcode"]),
      }));
      if (customerlist.value.length === 0) {
        await getCustomersAction();
      }
      let apiJson = convertToBookingModel(fileData.value);
      try {
        await addBookings(apiJson);
        toasted.success("Successfully Inserted Buck Records");
        fileData.value = null;
        filename.value = "";
        setTimeout(() => {
          window.location.reload();
        }, 5000);
      } catch (err: any) {
        actProperty.displayError(err);
      }
    };
    reader.readAsArrayBuffer(file);
  }
}

const getCustomersAction = async () => {
  try {
    await store.dispatch("pvbookings/getCustomers");
  } catch (err: any) {
    actProperty.displayError(err);
  }
};

// Function to convert tenant data into an array of Tenant instances
const convertToTenantArray = (data: any): Tenant[] => {
  const tenants: Tenant[] = [];
  let i = 1;

  while (data[`Tenant ${i}`]) {
    tenants.push(
      new Tenant({
        ttname: data[`Tenant ${i}`],
        ttemail: data[`Tenant ${i} Email`],
        ttmobile: data[`Tenant ${i} Mobile`],
      })
    );
    i++;
  }

  return tenants;
};

const subjobendDate = (time: string, date: Date) => {
  let value = "";
  if (time) {
    let dt: Date = date;
    let justdate = moment(dt).utc().format("YYYY-MM-DD");
    let justtime = moment(time, "hh:mm A").format("HH:mm");
    value = `${justdate}T${justtime}:00.000Z`;
  }
  return value;
};

// Assuming you have a function parseAddress as defined earlier
function convertToBookingModel(Datas: any[]): Booking[] {
  return Datas.map((data) => {
    const startTime = "T09:00:00.000Z";
    const endTime = "T10:00:00.000Z";
    let startdate = subjobendDate(startTime, data["Due Date"]);
    let enddate = subjobendDate(endTime, data["Due Date"]);
    let tenants = convertToTenantArray(data);
    let customer = customerlist.value.find(
      (f: Customer) =>
        f.companyName == data["Template"] && f.branchName == data["Branch"]
    );
    // Create a new BookingModel instance using the data
    const booking = new Booking({
      jobtype: data["Job Type"].toLowerCase(),
      address: data["Property"],
      duedate: data["Due Date"],
      startdate: startdate,
      enddate: enddate,
      tenancyid: data["ID"].toString(),
      tenants: tenants,
      pmname: data["Property Manager"],
      pmemail: data["Property Manager Email"],
      customer: customer,
    });
    delete booking.qc;
    return booking;
  });
}
// Function to parse address string into Address
function parseAddress(address: string, postcode: string): Address {
  // Flexible regex to handle addresses with building names, street, town, and postcode
  const parts = address.split(",").map((part) => part.trim());
  if (!parts.length) {
    throw new Error("Address format not recognized");
  }
  // Return new Address instance using the parsed data
  return new Address({
    line1: parts[1].trim(), // Street address
    line2: parts[2]?.trim(), // Optional second address line
    town: parts[3]?.trim(), // Town or city
    postcode: postcode, // Postcode (passed directly)
    country: "London", // Default to "London" as country
  });
}
// Convert serial number to JavaScript date
function excelDateToJSDate(serial) {
  const utc_days = Math.floor(serial - 25569);
  const utc_value = utc_days * 86400;
  const date_info = new Date(utc_value * 1000);

  return new Date(
    date_info.getFullYear(),
    date_info.getMonth(),
    date_info.getDate()
  );
}
const props = defineProps<{
  msg: string;
}>();
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
@import "@/assets/sass/bootstrap/_variables.scss";

.drag * {
  pointer-events: none;
}

.file-upload {
  border: 2px dashed rgba(0, 0, 0, 0);
  border-radius: 0.2rem;
  box-sizing: border-box;
  width: 100%;
  &.drag {
    background-color: $info-semi-opaque;
    border-color: $info;
  }
}

label {
  margin-bottom: 0;
  input[type="file"] {
    display: none;
  }
}
</style>
