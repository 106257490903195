<template>
  <div class="customers list">
    <div class="row mb-3">
      <div class="col-12">
        <div class="row">
          <div class="col-3">
            <div class="form-group row">
              <label class="col-sm-2 col-form-label">Duedate</label>
              <div class="col-7">
                <DxDateRangeBox
                  v-model="computedDateRange"
                  :min="minDate"
                  :max="maxDate"
                  displayFormat="dd/MM/yyyy"
                  label="Select Date Range"
                />
              </div>
              <div class="col-3" style="padding-top: 10px">
                <button class="btn btn-outline-secondary" @click="showAll()">
                  Show All
                </button>
              </div>
            </div>
          </div>
          <div class="col-3">
            <div class="form-group row">
              <label class="col-sm-2 col-form-label">PostCode</label>
              <div class="col-10">
                <multiselect-text
                  v-model="postCodeData"
                  :options="uniquePostcodes"
                  multiple
                  :close-on-select="false"
                  :allowEmpty="true"
                  taggable
                >
                  <!-- Template for rendering options with checkboxes -->
                  <template #option="{ option, selected, disabled }">
                    <div class="custom-checkbox">
                      <input
                        type="checkbox"
                        :checked="postCodeData.includes(option)"
                        :disabled="disabled"
                      />
                      <span style="padding-left: 10px"> {{ option }}</span>
                    </div>
                  </template>
                  <template #selection="{ values, search, isOpen }">
                    <span
                      class="multiselect__single"
                      v-if="values.length"
                      v-show="!isOpen"
                      >{{ values.length }} options selected</span
                    >
                  </template>
                </multiselect-text>
              </div>
            </div>
          </div>
          <div class="col-2">
            <div class="form-group row">
              <label class="col-sm-2 col-form-label">Status</label>
              <div class="col-10">
                <multiselect-text
                  v-model="statusData"
                  :options="statusList"
                  multiple
                  :close-on-select="false"
                  :allowEmpty="true"
                  taggable
                >
                  <!-- Template for rendering options with checkboxes -->
                  <template #option="{ option, selected, disabled }">
                    <div class="custom-checkbox">
                      <input
                        type="checkbox"
                        :checked="statusData.includes(option)"
                        :disabled="disabled"
                      />
                      <span style="padding-left: 10px"> {{ option }}</span>
                    </div>
                  </template>
                  <template #selection="{ values, search, isOpen }">
                    <span
                      class="multiselect__single"
                      v-if="statusData.length"
                      v-show="!isOpen"
                      >{{ statusData.length }} options selected</span
                    >
                  </template>
                </multiselect-text>
              </div>
            </div>
          </div>
          <div class="col-2">
            <div class="form-group row">
              <label class="col-sm-2 col-form-label">Client</label>
              <div class="col-10">
                <singleselect-text
                  v-model="clientData"
                  :options="uniqueClients"
                  :allow-empty="true"
                  :taggable="false"
                >
                  <template #clear v-if="clientData">
                    <i
                      @mousedown.prevent.stop="clientData = ''"
                      class="multiselect__clear fa fa-times"
                      aria-label="Clear Client"
                    ></i>
                  </template>
                </singleselect-text>
              </div>
            </div>
          </div>
          <div class="col-2">
            <div class="form-group row">
              <label class="col-sm-2 col-form-label">Branch</label>
              <div class="col-10">
                <singleselect-text
                  v-model="branchData"
                  :options="uniqueBranch"
                  :allow-empty="true"
                  :taggable="false"
                >
                  <template #clear v-if="branchData">
                    <i
                      @mousedown.prevent.stop="branchData = ''"
                      class="multiselect__clear fa fa-times"
                      aria-label="Clear Branch"
                    ></i>
                  </template>
                </singleselect-text>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div
      v-if="!propertyvisitmanagement.length && !hasBlockingRequests"
      class="alert alert-warning text-center"
    >
      <h2 class="mt-1 mb-2">No Property Visit Management found</h2>
    </div>

    <table v-else class="table table-hover">
      <thead>
        <tr>
          <th @click="sortTable('jobtype')" class="jobtype">
            Jobtype
            <span v-if="sortKey === 'jobtype'">{{
              sortOrder === "asc" ? "▲" : "▼"
            }}</span>
          </th>
          <th @click="sortTable('duedate')" class="duedate">
            Due Date
            <span v-if="sortKey === 'duedate'">{{
              sortOrder === "asc" ? "▲" : "▼"
            }}</span>
          </th>
          <th @click="sortTable('scheduleddate')" class="scheduledate">
            Scheduled Date
            <span v-if="sortKey === 'scheduleddate'">{{
              sortOrder === "asc" ? "▲" : "▼"
            }}</span>
          </th>
          <th @click="sortTable('daterangedate')" class="daterangedate">Date Range
            <span v-if="sortKey === 'daterangedate'">{{
              sortOrder === "asc" ? "▲" : "▼"
            }}</span>
          </th>
          <th @click="sortTable('status')" class="status">Status
            <span v-if="sortKey === 'status'">{{
              sortOrder === "asc" ? "▲" : "▼"
            }}</span>
          </th>
          <th @click="sortTable('postcode')" class="postcode">
            PostCode
            <span v-if="sortKey === 'postcode'">{{
              sortOrder === "asc" ? "▲" : "▼"
            }}</span>
          </th>
          <th @click="sortTable('address')" class="address">Address
            <span v-if="sortKey === 'address'">{{
              sortOrder === "asc" ? "▲" : "▼"
            }}
            </span>
          </th>
          <th @click="sortTable('pi')" class="pi">PI
            <span v-if="sortKey === 'pi'">{{
              sortOrder === "asc" ? "▲" : "▼"
            }}
            </span>
          </th>
          <th @click="sortTable('customer')" class="customer">Customer
            <span v-if="sortKey === 'customer'">{{
              sortOrder === "asc" ? "▲" : "▼"
            }}
            </span>
          </th>
          <th @click="sortTable('branch')" class="branch">Branch
            <span v-if="sortKey === 'branch'">{{
              sortOrder === "asc" ? "▲" : "▼"
            }}
            </span>
          </th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="(pvm, rowIndex) in sortedData" :key="pvm.id">
          <td>{{ pvm.jobtype.toLocaleUpperCase() }}</td>
          <td>{{ actProperty.formatDateForDisplay(pvm.duedate) }}</td>
          <td 
            contenteditable="false"
            @input="updateCellValue($event, rowIndex, pvm.id, 'scheduleddate')"
            @mousedown="startDrag(rowIndex, pvm.id, 'scheduleddate')"
            @mouseup="endDrag(pvm.id, 'scheduleddate')"
            @mousemove="handleDrag(rowIndex, 'scheduleddate')"
            class="no-select">
            <ScheduledDateSelect
              :pvm="pvm"
              :changesdatepvmid="changesdatepvmid"
              @schedulatedatepvmselected="changesdatepvmid = $event"
            />
          </td>
          <td  
            contenteditable="false"
            @input="updateCellValue($event, rowIndex, pvm.id, 'daterange')"
            @mousedown="startDrag(rowIndex, pvm.id, 'daterange')"
            @mouseup="endDrag(pvm.id, 'daterange')"
            @mousemove="handleDrag(rowIndex, 'daterange')"
            class="no-select">
            <DateRange
              :pvm="pvm"
              :changesdaterangepvmid="changesdaterangepvmid"
              @daterangepvmselected="changesdaterangepvmid = $event"
            />
          </td>
          <td
            contenteditable="false"
            @input="updateCellValue($event, rowIndex, pvm.id, 'status')"
            @mousedown="startDrag(rowIndex, pvm.id, 'status')"
            @mouseup="endDrag(pvm.id, 'status')"
            @mousemove="handleDrag(rowIndex, 'status')"
            class="no-select"
          >
            <StatusSelect
              :pvm="pvm"
              :changestatuspvmid="changestatuspvmid"
              @statuspvmselected="changestatuspvmid = $event"
            />
          </td>
          <td>
            {{ pvm.address.postcode }}
          </td>
          <td v-html="actProperty.formatAddress(pvm.address, ', ')"></td>
          <td
            contenteditable="false"
            @input="updateCellValue($event, rowIndex, pvm.id, 'inspector')"
            @mousedown="startDrag(rowIndex, pvm.id, 'inspector')"
            @mouseup="endDrag(pvm.id, 'inspector')"
            @mousemove="handleDrag(rowIndex, 'inspector')"
            class="no-select"
          >
            <InspectorSelect
              :pvm="pvm"
              :changeinspectorpvmid="changeinspectorpvmid"
              @inspectorpvmselected="changeinspectorpvmid = $event"
            />
          </td>
          <td>{{ pvm.customer.companyName }}</td>
          <td>{{ pvm.customer.branchName }}</td>
        </tr>
      </tbody>
    </table>
    <PropertyVisitPagination
       v-if="sortedData.length"
      :total="pvmstate.totalcount"
      :current="pvmstate.currentPage"
      :limit="pvmstate.pageLimit"
      @pageReports="doReportsPaging"
    />
  </div>
</template>

<script lang="ts" setup>
import { Booking, Inspector } from "@/models";
import { computed, onMounted, inject, ref, watch } from "vue";
import { useStore } from "vuex";
import { DxDateRangeBox } from "devextreme-vue/date-range-box";
import InspectorSelect from "@/components/propertyvisitmanagement/InspectorSelect.vue";
import ScheduledDateSelect from "@/components/propertyvisitmanagement/ScheduledDateSelect.vue";
import StatusSelect from "@/components/propertyvisitmanagement/StatusSelect.vue";
import DateRange from "@/components/propertyvisitmanagement/DateRange.vue";
import PropertyVisitPagination from "@/components/propertyvisitmanagement/PropertyVisitPagination.vue";
import { useRouter, useRoute } from 'vue-router';
const pvmstate = computed(() => store.state.propertyvisitmanagement);
import moment from "moment";
const store = useStore();
const postCodeOptions = ref([]);
const clientOptions = ref("");
const branchOptions = ref("");
const router = useRouter();
const route = useRoute();
const statusOptions = ref([]);
const propertyvisitmanagementData = ref<Booking[]>([]);
const hasBlockingRequests = computed(
  () => store.getters["app/hasBlockingRequests"]
);
const inspectorlist = computed(() => store.getters["diary/inspectorlist"]);
const setBookingsData = (data: Booking[]) =>
  store.commit("propertyvisitmanagement/setBookings", data);
const actProperty: any = inject("actProperty");
const getBookings = (payload: { startdate: string; enddate: string }) => {
  return store.dispatch("propertyvisitmanagement/getBookings", payload);
};
const setCurrentPage = (page: number): Promise<any> => {
  return store.dispatch('propertyvisitmanagement/setCurrentPage', page);
};
const resetCurrentPage = (page: number): Promise<any> => {
  return store.dispatch('propertyvisitmanagement/resetCurrentPage', page);
};
const dateRange = ref<[Date | null, Date | null]>([null, null]);
const changestatuspvmid = ref("");
const changeinspectorpvmid = ref("");
const changesdatepvmid = ref("");
const changesdaterangepvmid = ref("");
const computedDateRange = computed<[Date | null, Date | null]>({
  get() {
    return dateRange.value;
  },
  set(value: [Date | null, Date | null]) {
    dateRange.value = value;
    if (dateRange.value[0] && dateRange.value[1]) {
      getBookingsData();
    }
  },
});
const sortKey = ref(""); // Holds the key of the column being sorted
const sortOrder = ref<"asc" | "desc">("asc"); // Sorting order
const statusList: string[] = [
  "Select All",
  "Not due",
  "Due",
  "Scheduled and in queue",
];

// Sorting function
const sortTable = (key: string) => {
  if (sortKey.value === key) {
    // If the same column is clicked, toggle the sort order
    sortOrder.value = sortOrder.value === "asc" ? "desc" : "asc";
  } else {
    // If a new column is clicked, set to ascending order
    sortKey.value = key;
    sortOrder.value = "asc";
  }
};

// Computed property for sorted data
const sortedData = computed(() => {
  const sorted = [...propertyvisitmanagementData.value];
  // Define sorting logic based on the selected key
  if (sortKey.value) {
    sorted.sort((a, b) => {
      let compareA = a[sortKey.value] ?? "";
      let compareB = b[sortKey.value] ?? "";

      // Handle nested fields like postcode, customer, and address
      if (sortKey.value === "postcode") {
        compareA = a.address.postcode;
        compareB = b.address.postcode;
      }

      // Handle date sorting
      if (sortKey.value === "duedate") {
        compareA = new Date(a.duedate);
        compareB = new Date(b.duedate);
      }

      // Handle date sorting
      if (sortKey.value === "scheduleddate") {
        compareA = new Date(a.scheduleddate);
        compareB = new Date(b.scheduleddate);
      }

      // Handle date sorting
      if (sortKey.value === "daterangedate") {
        compareA = new Date(a.daterangestartdate);
        compareB = new Date(b.daterangeenddate);
      }

      // Handle date sorting
      if (sortKey.value === "status") {
        compareA = a.status;
        compareB = b.status;
      }

      // Handle date sorting
      if (sortKey.value === "pi") {
        compareA = a.inspector.name;
        compareB = b.inspector.name;
      }

      // Handle date sorting
      if (sortKey.value === "customer") {
        compareA = a.customer.companyName;
        compareB = b.customer.companyName;
      }

      // Handle date sorting
      if (sortKey.value === "branch") {
        compareA = a.customer.branchName;
        compareB = b.customer.branchName;
      }

      // For string comparison
      if (typeof compareA === "string") {
        compareA = compareA.toLowerCase();
        compareB = compareB.toLowerCase();
      }

      if (compareA < compareB) return sortOrder.value === "asc" ? -1 : 1;
      if (compareA > compareB) return sortOrder.value === "asc" ? 1 : -1;
      return 0;
    });
  }

  // Handle date sorting
  if (sortKey.value === "address") {
    sorted.sort((a: Booking, b: Booking) => {
      const direction = sortOrder.value === "asc" ? 1 : -1; // Determine sort direction

      // Compare `line1`
      let result = a.address.line1.localeCompare(b.address.line1) * direction;
      if (result !== 0) return result;

      // Compare `line2` (optional field)
      result = (a.address.line2 || "").localeCompare(b.address.line2 || "") * direction;
      if (result !== 0) return result;

      // Compare `town`
      result = a.address.town.localeCompare(b.address.town) * direction;
      if (result !== 0) return result;

      // Compare `county` (optional field)
      result = (a.address.county || "").localeCompare(b.address.county || "") * direction;
      if (result !== 0) return result;

      // Compare `postcode`
      return a.address.postcode.localeCompare(b.address.postcode) * direction;
    });   
  }

  let filtered = [...sorted];

  // Filter propertyvisitmanagementData based on postcodes
  // Filter by postcode if postCodeData is not empty
  if (postCodeData.value.length) {
    filtered = filtered.filter((item: Booking) =>
      postCodeData.value.includes(item.address.postcode)
    );
  }

  // Filter by status if statusData is not empty
  if (statusData.value.length) {
    // filtered = filtered.filter((item: Booking) =>
    //   statusData.value.includes(item.id) // Assuming `item.status` is the status field in `Booking`
    // );
  }

  // Filter by client
  if (clientData.value.length) {
    filtered = filtered.filter((item: Booking) =>
      clientData.value.includes(item.customer.companyName)
    );
  }
  // Filter by client
  if (branchData.value.length) {
    filtered = filtered.filter((item: Booking) =>
      branchData.value.includes(item.customer.branchName)
    );
  }

  return filtered;
});
const postCodeData = computed({
  get() {
    return postCodeOptions.value;
  },
  set(val: string[]) {
    if (
      val.includes("Select All") &&
      !postCodeOptions.value.includes("Select All")
    ) {
      postCodeOptions.value = uniquePostcodes.value;
    } else if (
      !val.includes("Select All") &&
      postCodeOptions.value.includes("Select All")
    ) {
      postCodeOptions.value = [];
    } else {
      postCodeOptions.value = val.filter((f: any) => f !== "Select All");
    }
  },
});
const clientData = computed({
  get() {
    return clientOptions.value;
  },
  set(val: string) {
    clientOptions.value = val;
  },
});
const branchData = computed({
  get() {
    return branchOptions.value;
  },
  set(val: string) {
    branchOptions.value = val;
  },
});
const statusData = computed({
  get() {
    return statusOptions.value;
  },
  set(val: string[]) {
    if (
      val.includes("Select All") &&
      !statusOptions.value.includes("Select All")
    ) {
      statusOptions.value = statusList;
    } else if (
      !val.includes("Select All") &&
      statusOptions.value.includes("Select All")
    ) {
      statusOptions.value = [];
    } else {
      statusOptions.value = val.filter((f: any) => f !== "Select All");
    }
  },
});
const formatDate = (date: Date) => {
  return moment(date).format("DD-MM-YYYY");
};
watch(() => route.query.page, async (newVal: any, oldVal: any) => {
  if (newVal) {
    await setCurrentPage(newVal);
    await getBookingsData();
  }
});
const propertyvisitmanagement = computed(
  () => store.getters["propertyvisitmanagement/getBookingsData"]
);
const getBookingsData = async () => {
  if (dateRange.value[0] && dateRange.value[1]) {
    // Format the start and end dates and make the API call
    await getBookings({
      startdate: formatDate(dateRange.value[0]), // Format the start date
      enddate: formatDate(dateRange.value[1]), // Format the end date
    });
  } else {
    await getBookings({
      startdate: "", // Format the start date
      enddate: "", // Format the end date
    });
  }
  propertyvisitmanagementData.value = propertyvisitmanagement.value;
};
const uniquePostcodes = computed(() => {
  let postcodes = propertyvisitmanagement.value.map(
    (f: Booking) => f.address.postcode
  );

  // Remove duplicates using Set and convert back to an array
  const uniquePostcodesArray = Array.from(new Set(postcodes));

  // Add 'Select All' at the 0th index
  return ["Select All", ...uniquePostcodesArray];
});
const uniqueClients = computed(() => {
  let clients = propertyvisitmanagement.value.map(
    (f: Booking) => f.customer.companyName
  );

  // Remove duplicates using Set and convert back to an array
  const uniquePostcodesArray = Array.from(new Set(clients));

  // Add 'Select All' at the 0th index
  return [...uniquePostcodesArray];
});
const uniqueBranch = computed(() => {
  if (!clientData.value) return [];
  let clients = propertyvisitmanagement.value
    .filter((f: Booking) => f.customer.companyName == clientData.value)
    .map((f: Booking) => f.customer.branchName);

  // Remove duplicates using Set and convert back to an array
  const uniquePostcodesArray = Array.from(new Set(clients));

  // Add 'Select All' at the 0th index
  return [...uniquePostcodesArray];
});

// Converted methods
const init = async () => {
  await getBookingsData();
};
const showAll = async () => {
  dateRange.value = [null, null]; // Reset date range
  resetCurrentPage(1);
  setCurrentPage(1);
  await getBookingsData();
};
const minDate = new Date("2020-01-01"); // Very old date (acts as "infinity" in the past)
const maxDate = new Date("2050-12-31"); // Very distant future date (acts as "infinity" in the future)
// Lifecycle hook
onMounted(() => {
  init();
});

// Reactive state for drag-to-fill functionality
const dragging = ref(false);
const startRow = ref<number | null>(null);
const startValue = ref<string | null>(null);
const endValue = ref<string | null>(null);
const endRow = ref<number | null>(null);
// Function to get the inspector ID based on the name and set it in the appropriate cell
const getUpdateFieldById = (
  rowIndex: number,
  name: string,
  id: string,
  fieldname: string
) => {
  const pmIndex = propertyvisitmanagement.value.findIndex(
    (r: Booking) => r.id === id
  );
  if (fieldname === "inspector") {
    const inspector = inspectorlist.value.find(
      (f: Inspector) => f.name === name
    );
    if (inspector?.id) {
      sortedData.value[rowIndex].inspector.id = inspector.id;
      sortedData.value[rowIndex].inspector.name = name;
      propertyvisitmanagement.value[pmIndex].inspector = inspector;
    }
  } else if (fieldname === "status") {
    sortedData.value[rowIndex].status = name;
    propertyvisitmanagement.value[pmIndex].status = name;
  } else if (fieldname === "scheduleddate" && name !== null) {
    sortedData.value[rowIndex].scheduleddate = name;
    propertyvisitmanagement.value[pmIndex].scheduleddate = name;
  } else if (fieldname === "daterange" && name !== null) {
    sortedData.value[rowIndex].daterangestartdate = name;
    propertyvisitmanagement.value[pmIndex].daterangestartdate = name;
    sortedData.value[rowIndex].daterangeenddate = endValue.value;
    propertyvisitmanagement.value[pmIndex].daterangeenddate =  endValue.value
  }
  setBookingsData(propertyvisitmanagement.value);
};

// Function to update the cell value when a user enters data
const updateCellValue = (
  event: InputEvent,
  rowIndex: number,
  id: string,
  fieldname: string
) => {
  const value = (event.target as HTMLElement).innerText;
  getUpdateFieldById(rowIndex, value, id, fieldname);
};

// Function to initiate drag-to-fill (stores initial row and value)
const startDrag = (rowIndex: number, id: string, fieldname: string) => {
  dragging.value = true;
  startRow.value = rowIndex;
  if (fieldname === "inspector") {
    startValue.value = sortedData.value[rowIndex].inspector.name; // Capture the initial value
  } else if (fieldname === "status") {
    startValue.value = sortedData.value[rowIndex].status; // Capture the initial value
  } else if (fieldname === "scheduleddate") {
    startValue.value = sortedData.value[rowIndex].scheduleddate; // Capture the initial value
  } else if (fieldname === "daterange") {
    startValue.value = sortedData.value[rowIndex].daterangestartdate; // Capture the initial value
    endValue.value = sortedData.value[rowIndex].daterangeenddate; // Capture the initial value
  }
  getUpdateFieldById(rowIndex, startValue.value, id, fieldname);
  endRow.value = rowIndex; // Initialize endRow as startRow to reset drag boundaries
};

// Function to finalize the drag-to-fill action, filling cells downward only
const endDrag = (id: string, fieldname: string) => {
  if (
    dragging.value &&
    startRow.value !== null &&
    startValue.value !== null &&
    endRow.value !== null &&
    endRow.value > startRow.value // Ensure we’re only dragging downward
  ) {
    dragging.value = false;

    // Fill cells only downward from startRow to endRow
    const start = startRow.value;
    const end = endRow.value;

    for (let i = start; i <= end; i++) {
      getUpdateFieldById(i, startValue.value, id, fieldname);
    }
  }
  // Reset start and end rows after drag
  startRow.value = null;
  endRow.value = null;
};

// Function to track the drag row index (determines the end row)
// Only allow downward dragging (endRow >= startRow)
const handleDrag = (rowIndex: number, fieldname: string) => {
  if (dragging.value && rowIndex > startRow.value) {
    endRow.value = rowIndex;
  }
};
const doReportsPaging = (page: number): void => {
  setCurrentPage(page);
  const queryParams = { ...route.query };
  queryParams.page = page + ""; // hack to coerce to string
  router.push({ path: "propertyvisitmanagements", query: queryParams });
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
th {
  &.jobtype {
    width: 8rem;
  }
  &.duedate {
    width: 8rem;
  }
  &.scheduledate {
    width: 8rem;
  }
  &.schedate {
    width: 8rem;
  }
  &.status {
    width: 10rem;
  }
  &.postcode {
    width: 8rem;
  }
  &.address {
    width: 15rem;
  }
  &.pi {
    width: 8rem;
  }
  &.customer {
    width: 8rem;
  }
  &.branch {
    width: 8rem;
  }
  &.daterangedate {
    width: 11rem;
  }
}
.no-select {
  user-select: none;
}
</style>
