import { API } from 'aws-amplify';

import { ActionTree, ActionContext } from 'vuex';
import { RootState } from '../types';
import { PropertyVisitManagementState } from './types';
import { Booking, PmTenancy } from '@/models';
import _ from 'lodash';
/**
 * Actions
 *
 * Actions are similar to mutations, the differences being that:
 * - Instead of mutating the state, actions commit mutations.
 * - Actions can contain arbitrary asynchronous operations.
 * - Actions are triggered with the store.dispatch method e.g. `store.dispatch('getSettings')`
 */
export const actions: ActionTree<PropertyVisitManagementState, RootState> = {

  /**
   * Get all Tenancies
   * 
   * @param {PropertyVisitManagementState} store 
   * @param filters 
   * @returns All Tenancies
   */
  async getTenancies(store: ActionContext<PropertyVisitManagementState, any>, data: any): Promise<PmTenancy[]> {
    store.commit('app/addRequest', 'getTenancies', { root: true });
    const options = {
      queryStringParameters: {
        startdate: data.startdate,
        enddate: data.enddate
      },
    };
    return API.get('RestAPI', '/pmtenancies', options)
      .then((response) => response.map((x: Partial<PmTenancy>) => new PmTenancy(x)))
      .then((tenancies: PmTenancy[]) => {
        store.commit('setTenancies', tenancies);
        return tenancies;
      })
      .finally(() => store.commit('app/removeRequest', 'getTenancies', { root: true }));
  },
  /**
  * Get multiple Bookings
  *
  * @param {DiaryState} store
  * @param filters
  * @returns Multiple Bookings
  */
  async getBookings(
    store: ActionContext<PropertyVisitManagementState, any>,
    filters: any
  ): Promise<Booking[]> {
    const options = {
      response: true, // OPTIONAL (return the entire Axios response object instead of only response.data)
      queryStringParameters: _.pickBy(
        _.pick(filters, ["startdate", "enddate"])
      ),
    };

     // Check for page params for paginaton and offset
     if (store.state.currentPage) {
        _.set(options, "queryStringParameters.page", store.state.currentPage);
      } else {
        _.set(options, "queryStringParameters.page", 1);
      }


    store.commit("app/addRequest", "getBookings", { root: true });

    return API.get("RestAPI", "/pvbookings", options)
      .then((response) => {        
        if (response.headers.hasOwnProperty("x-act-total-count")) {
          store.commit(
            "setPvCount",
            response.headers["x-act-total-count"]
          );
        } else {
          store.commit("setPvCount", null);
        }
        if (response.headers.hasOwnProperty("x-act-pv-page-limit")) {
          store.commit(
            "setPageLimit",
            response.headers["x-act-pv-page-limit"]
          );
        }
        return response.data.map((x: Partial<Booking>) => new Booking(x))
      })
      .then((bookings: Booking[]) => {
        store.commit("setBookings", bookings);
        return bookings;
      })
      .finally(() =>
        store.commit("app/removeRequest", "getBookings", { root: true })
      );
  },
   /**
   * Set the page limit for pagination
   *
   * @param store
   * @param data
   */
   setPageLimit(store: ActionContext<PropertyVisitManagementState, any>, data): void {
    store.commit("setPageLimit", data);
  },

  /**
   * Set the page for the Reports query
   *
   * @param store
   * @param data
   */
  setCurrentPage(store: ActionContext<PropertyVisitManagementState, any>, data): void {
    store.commit("setCurrentPage", data);
  },

  /**
   * Set the page for the Reports query
   *
   * @param store
   * @param data
   */
  resetCurrentPage(store: ActionContext<PropertyVisitManagementState, any>): void {
    store.commit("setCurrentPage", 1);
  },

};
