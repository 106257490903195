<template>
  <div @focusout="closeDropdown">
    <singleselect-text
      v-model="status"
      :options="statusList"
      :allow-empty="true"
      v-show="changestatuspvmid === pvm.id"
      :taggable="false"
      @select="onSelectStatus(pvm.id, $event)"
      :ref="'statusSelect-' + pvm.id"
    >
      <template #clear v-if="pvm.id">
        <i
          @mousedown.prevent.stop="onSelectStatus(pvm.id, $event)"
          class="multiselect__clear fa fa-times"
          aria-label="Clear"
        ></i>
      </template>
    </singleselect-text>
    <div
      v-show="changestatuspvmid != pvm.id"
      :class="{ 'transparent-text': !pvm.status }"
      @click="changeStatusReport()"
    >
      {{ pvm.status ? pvm.status : "Select Dropdown" }}
    </div>
  </div>
</template>

<script lang="ts" setup>
import { ref, computed, toRefs, defineEmits, defineProps } from "vue";
import { useStore } from "vuex";
import { Booking } from "@/models";

const store = useStore();
const statusList: string[] = [
  "Not due",
  "Due",
  "Scheduled and in queue",
];
const propertyvisitmanagement = computed(
  () => store.getters["propertyvisitmanagement/getBookingsData"]
);
const props = defineProps<{
  pvm: Booking;
  changestatuspvmid: string;
}>();
const emit = defineEmits(["statuspvmselected"]);
const statusSelect = ref<{ [key: string]: HTMLElement | undefined }>({});
const setBookingsData = (data: Booking[]) =>
  store.commit("propertyvisitmanagement/setBookings", data);
const { pvm, changestatuspvmid } = toRefs(props);
const changeStatusReport = () => {
  emit("statuspvmselected", pvm.value.id);

  const dropdown = statusSelect.value[`statusSelect-${pvm.value.id}`] as any;
  if (dropdown) {
    dropdown.activate();
  }
};
const onSelectStatus = async (id: string | undefined, val: string) => {
  const pmIndex = propertyvisitmanagement.value.findIndex(
    (r: Booking) => r.id === id
  );
  propertyvisitmanagement.value[pmIndex].status = val;
  setBookingsData(propertyvisitmanagement.value);
  pvm.value.id = id;
  emit("statuspvmselected", "");
};
const closeDropdown = () => {
  emit("statuspvmselected", "");
};
const status = ref("");
</script>

<style scoped lang="scss">
.emptystatus {
  color: rgb(255, 255, 255);
  background-color: tomato;
}
.transparent-text {
  color: transparent;
}
</style>

<style lang="scss">
.statusdtopdown > .multiselect__content-wrapper {
  width: fit-content;
}
</style>
