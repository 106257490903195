<template>
    <nav id="reports-pagination">
      <ul v-if="paging.numPages" id="reports-pagination-list" class="pagination">
        <li v-for="(page, $index) in paging.numPages" :key="$index" :class="pageIsActive($index+1, 'page-item')">
          <button @click="setPage(page)" class="page-link">
            {{ page }}
          </button>
        </li>
      </ul>
      <!-- <pre>{{ paging }}</pre> -->
    </nav>
</template>

<script lang="ts" setup>
import { computed, defineEmits, defineProps } from 'vue';
const emit = defineEmits(['pageReports'])

const props = defineProps<{
  total?: number | string;
  current: number | string;
  limit: number | string;
}>();
// Convert getters to computed properties
const totalReports = computed(() => props.total);
const pageLimit = computed(() => parseInt(props.limit as string, 10));
const currentPage = computed(() => parseInt(props.current as string, 10));

const paging = computed(() => {
  const numPages = Math.ceil(parseInt(totalReports.value as string, 10) / pageLimit.value);
  return {
    currentPage: currentPage.value,
    numPages
  };
});

const pageIsActive = (p: number, classNames: string) => {
  const classes = [classNames];
  if (p === currentPage.value) {
    classes.push('active');
  }
  return classes.join(' ');
};

const setPage = (value: number) => {
  emit('pageReports', value)
};
</script>


<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
.pagination {
  display: block;
}

li.page-item {
  display: inline-block;
  padding-bottom: 1%;
}
</style>
