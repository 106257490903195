<template>
  <div @focusout="closeDropdown">
    <DxDateRangeBox
      v-model="computedDateRange"
      :min="minDate"
      :max="maxDate"
      displayFormat="dd/MM/yyyy"
      label="Select Date Range"
      :ref="'dynamicDateRangeRefs-' + pvm.id"
      v-show="changesdaterangepvmid === pvm.id"
      @value-changed="setScheduledDate(pvm.id, $event.value)"
    />
    <div
      v-show="changesdaterangepvmid !== pvm.id"
      :class="{ 'transparent-text': !pvm.daterangestartdate }"
      @click="changeDateRangeReport()"
    >
      {{
        pvm.daterangestartdate && pvm.daterangeenddate
          ? actProperty.formatDateForDisplay(pvm.daterangestartdate) +
            " - " +
            actProperty.formatDateForDisplay(pvm.daterangeenddate)
          : "Select"
      }}
    </div>
  </div>
</template>
<script lang="ts" setup>
import { ref, computed, toRefs, defineEmits, defineProps, inject } from "vue";
import { useStore } from "vuex";
import { Booking } from "@/models";
import moment from "moment-timezone";
import { DxDateRangeBox } from "devextreme-vue/date-range-box";
const store = useStore();
const actProperty: any = inject("actProperty");
const propertyvisitmanagement = computed(
  () => store.getters["propertyvisitmanagement/getBookingsData"]
);
const props = defineProps<{
  pvm: Booking;
  changesdaterangepvmid: string;
}>();
const emit = defineEmits(["daterangepvmselected"]);
const minDate = new Date("2020-01-01"); // Very old date (acts as "infinity" in the past)
const maxDate = new Date("2050-12-31"); // Very distant future date (acts as "infinity" in the future)
const dynamicDateRangeRefs = ref<{ [key: string]: HTMLElement | undefined }>(
  {}
);
const setBookingsData = (data: Booking[]) =>
  store.commit("propertyvisitmanagement/setBookings", data);
const { pvm, changesdaterangepvmid } = toRefs(props);
const changeDateRangeReport = () => {
  emit("daterangepvmselected", pvm.value.id);

  const dropdown = dynamicDateRangeRefs.value[
    `dynamicDateRangeRefs-${pvm.value.id}`
  ] as any;
  if (dropdown) {
    dropdown.activate();
  }
};
const closeDropdown = () => {
  emit("daterangepvmselected", "");
};
const dateRange = ref<[Date | null, Date | null]>([null, null]);
const computedDateRange = computed(
  () => dateRange.value
);
const setScheduledDate = async (id: string, value: any) => {
  if (value[0] && value[1]) {
    dateRange.value = value;
    const pmIndex = propertyvisitmanagement.value.findIndex(
      (r: Booking) => r.id === props.pvm.id
    );
    propertyvisitmanagement.value[pmIndex].daterangestartdate = moment(
      value[0]
    ).format("YYYY-MM-DD[T]HH:mm");
    propertyvisitmanagement.value[pmIndex].daterangeenddate = moment(
      value[1]
    ).format("YYYY-MM-DD[T]HH:mm");
    setBookingsData(propertyvisitmanagement.value);
    pvm.value.id = id;
    emit("daterangepvmselected", "");
  }
};
</script>

<style scoped lang="scss">
.emptystatus {
  color: rgb(255, 255, 255);
  background-color: tomato;
}
.transparent-text {
  color: transparent;
}
</style>

<style lang="scss">
.statusdtopdown > .multiselect__content-wrapper {
  width: fit-content;
}
</style>
