<template>
  <div>
    <div class="row">
      <div class="col-8 mb-3">
        <div class="dx-item-content dx-toolbar-item-content">
          <div role="group" class="dx-buttongroup dx-widget" tabindex="0">
            <div class="dx-buttongroup-wrapper dx-widget dx-collection">
              <div
                class="dx-widget dx-button dx-button-mode-contained dx-button-normal dx-button-has-icon dx-item dx-buttongroup-item dx-item-content dx-buttongroup-item-content dx-buttongroup-first-item dx-shape-standard dx-scheduler-navigator-previous"
                role="button"
                aria-label="chevronprev"
                @click="prevdate"
              >
                <div class="dx-button-content">
                  <i class="dx-icon dx-icon-chevronprev"></i>
                </div>
              </div>
              <div
                class="dx-widget dx-button dx-button-mode-contained dx-button-normal dx-button-has-text dx-item dx-buttongroup-item dx-item-content dx-buttongroup-item-content dx-shape-standard dx-scheduler-navigator-caption"
                role="button"
                aria-label=""
              >
                <DxDateBox
                  v-model="currentdate"
                  display-format="EEEE dd MMMM yyyy"
                  type="date"
                  picker-type="calendar"
                  apply-value-mode="instantly"
                  class="pidatebox"
                  :calendarOptions="{firstDayOfWeek:1}"
                  :max="maxdate"
                />
              </div>
              <div
                class="dx-widget dx-button dx-button-mode-contained dx-button-normal dx-button-has-icon dx-item dx-buttongroup-item dx-item-content dx-buttongroup-item-content dx-buttongroup-last-item dx-shape-standard dx-scheduler-navigator-next"
                role="button"
                aria-label="chevronnext"
                @click="nextdate"
              >
                <div class="dx-button-content">
                  <i class="dx-icon dx-icon-chevronnext"></i>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="col-4">
        <button class="btn btn-light" title="Today" @click="today">
          <i class="far fa-calendar-check"></i> Today
        </button>
      </div>
    </div>
    <div class="row">
      <div v-if="showtimeline" class="timeline" :style="{ 'top': timelineposition }"></div>
      <DxScheduler id="diary-scheduler" :key="`diary-scheduler-${bookings.length}`" ref="schedulerRef" class="pischeduler" 
        time-zone="Etc/UTC" :data-source="bookings"
        :current-date="currentdate" :views="[]" :groups="groups" :height="height - (height * 0.15)"
        :width="width - (width * 0.02)" :show-all-day-panel="true" :all-day-panel-mode="'hidden'" :first-day-of-week="1"
        :start-day-hour="7" :end-day-hour="21" :cell-duration="15" :allow-adding="true" :allow-updating="false"
        :editing="editing" :appointment-dragging="appointmentdragging" :show-current-time-indicator="false"
        :indicator-time="new Date()" current-view="day" text-expr="summary" 
        start-date-expr="startdate" end-date-expr="enddate" 
        appointment-template="bookingTemplate" resource-cell-template="inspectorTemplate" time-cell-template="timeCellTemplate"
        :cross-scrolling-enabled="false" :on-option-changed="onOptionChanged"
        :on-content-ready="schedulerContentReady" @appointment-click="onAppointmentClick">

        <DxResource
          :data-source="employees"
          :allow-multiple="false"
          label="Inspectors"
          field-expr="employeeId"
        />

        <template #inspectorTemplate="{ data: employee }">
          <InspectorTemplate
            :employee="employee"
            :pidiary="true"
          />
        </template>

        <template #timeCellTemplate="{ data: cellData }">
          <TimeCellTemplate :cell-data="cellData" />
        </template>

        <template #bookingTemplate="{ data }">
          <BookingTemplate :template-model="data"/>
        </template>
        <DxScrolling mode="standard" />
      </DxScheduler>
      <BookingDetailModal id="pischedular-booking-detail-model" ref="bookingDetailModal" :editable="false"/>
      <RevisitDetailModal id="pischedular-revisit-detail-model" ref="revisitDetailModal"/>
    </div>
  </div>
</template>

<script lang="ts" setup>
import { ref, computed, onMounted } from 'vue';
import moment from 'moment-timezone';
import { useStore } from 'vuex';
import { Booking, Inspector } from "@/models";
import BookingDetailModal from "@/components/modals/BookingDetailModal.vue";
import RevisitDetailModal from "@/components/modals/RevisitDetailModal.vue";
import TimeCellTemplate from "./TimeCellTemplate.vue";
import BookingTemplate from "./BookingTemplate.vue";
import InspectorTemplate from "./InspectorTemplate.vue";

import { DxDateBox } from "devextreme-vue/date-box";
import {
  DxScheduler,
  DxResource,
  DxScrolling,
} from "devextreme-vue/scheduler";

// Define store
const store = useStore();

// Define refs and reactive properties
const editing = ref({
  allowAdding: false,
  allowDeleting: false,
  allowUpdating: false,
  allowResizing: false,
  allowDragging: false,
});
const appointmentdragging = ref({ autoScroll: false });
const showtimeline = ref(true);
const timelineposition = ref("125.5px");
const loggedininspector = ref<Inspector | undefined>(new Inspector());
const height = ref(window.innerHeight);
const width = ref(window.innerWidth);
const _currentdate = ref<Date>(new Date());

const bookingDetailModal = ref(null);
const revisitDetailModal = ref(null);
const email = computed(() => store.getters['auth/email']);
const bookings = computed(() => store.getters['diary/list']);
const getBookings = async (payload?: { date?: string; inspectorid?: string }): Promise<Booking[]> => {
  return await store.dispatch('diary/getBookings', payload);
};

const getInspectorByEmail = async (email: string): Promise<Inspector> => {
  return await store.dispatch('diary/getInspectorByEmail', email);
};

const setCurrentdate = (currentdate: Date): void => {
  getBookings({ date: moment(currentdate).format("DD-MM-YYYY"), inspectorid: loggedininspector.value.id });
  store.commit('diary/setCurrentdate', currentdate);
};

const onOptionChanged = (args: any) => {
  // if (args.name === "currentDate" && loggedininspector.value) {
  //   getBookings({ date: moment(args.value).format("DD-MM-YYYY"), inspectorid: loggedininspector.value.id });
  // }
};

const schedulerContentReady = (e: any) => {
  e.component.getWorkSpaceScrollable().option('showScrollbar', 'always');
  e.component.getWorkSpaceScrollable().option('useNative', 'true');
};

const onAppointmentClick = (e: any) => {
  e.cancel = true;
  if (e.appointmentData) {
    let booking: Booking = e.appointmentData;
    if(booking.subtype != Booking.PERSONAL) {
      if(booking.subtype === Booking.REVISIT) {
        const modal = revisitDetailModal.value as any;
        if (modal) {
          modal.init(booking, false);
          modal.show();
        }
      }
      else {
        const modal = bookingDetailModal.value as any;
        if (modal) {
          modal.init(booking);
          modal.show();
        }
      }
    }
  }
};

// Define computed properties
const currentdate = computed({
  get: () => _currentdate.value,
  set: (val: Date) => {
    let days = currentdatedifferencewithtoday(val);
    if (days < 8) {
      setCurrentdate(val);
    } else {
      const valmoment = moment(new Date()).utc().startOf("day");
      let adjusteddate = valmoment.add(7, 'days').toDate();
      setCurrentdate(adjusteddate);
    }
  }
});

const groups = computed(() => ["employeeId"]);
const employees = computed(() => [loggedininspector.value]);

const currentdatedifferencewithtoday = (val: Date) => {
  const todaymoment = moment(new Date()).utc().startOf('day');
  const currentmoment = moment(val).utc().startOf("day");
  let days = moment.duration(currentmoment.diff(todaymoment)).asDays();
  return days + 1;
};

const maxdate = computed(() => {
  const todaymoment = moment(new Date()).utc().startOf('day');
  return todaymoment.add(7, "days").toDate();
});

onMounted(async () => {
  loggedininspector.value = await getInspectorByEmail(email.value);
    if (loggedininspector.value) {
      getBookings({ date: moment(currentdate.value).format("DD-MM-YYYY"), inspectorid: loggedininspector.value.id });

      setInterval(() => {
        const now = new Date();
        const currentdatesameastoday = moment(currentdate.value).isSame(now, 'day');
        const hours = now.getHours();
        const hourswithincalendarbounds = (hours >= 7 && hours <= 21);
        showtimeline.value = currentdatesameastoday && hourswithincalendarbounds;
        if(showtimeline.value) {
          let currentTime = now.getTime();
          const today = new Date(now.getFullYear(), now.getMonth(), now.getDate());
          const targetTime = new Date(today.getTime() + 7 * 60 * 60 * 1000);
          const epochValueUntilSevenAM = targetTime.getTime();
          currentTime = currentTime - epochValueUntilSevenAM;
          const minuteHeight = window.innerHeight * 0.011669 / 15;
          const currentMinutes = currentTime / 60000;
          let newposition = Math.round(currentMinutes * minuteHeight);
          newposition = newposition + 128.5;
          timelineposition.value = `${newposition}px`;
        }
      }, 6000);
    }
});

const prevdate = () => {
  _currentdate.value = moment(_currentdate.value)
    .utc()
    .subtract(1, "days")
    .toDate();
};

const nextdate = () => {
  _currentdate.value = moment(_currentdate.value).utc().add(1, "days").toDate();
};

const today = () => {
  _currentdate.value = moment().utc().toDate();
};


</script>


<style scoped lang="scss">

.pidatebox ::v-deep .dx-datebox-wrapper-calendar > .dx-calendar {
  min-width: 282px;
}
.pidatebox {
  border: none !important;
}

.dx-scheduler-group-header,
.dx-scheduler-date-table-cell {
  position: relative;
}

.dx-scheduler-group-header-content > .selected {
  background-color: rgb(197, 197, 197);
}

.datebox .dx-texteditor-input {
  width: 180px !important;
}

.dx-scheduler-date-table-other-month.dx-scheduler-date-table-cell {
  opacity: 1;
  color: rgba(0, 0, 0, 0.3);
}

.dx-scheduler-date-table-cell .dx-template-wrapper {
  position: absolute;
  width: 100%;
  height: 100%;
  padding-right: 6px;
}
.dx-scheduler-group-header,
.dx-scheduler-cell-sizes-horizontal {
  width: 130px;
}

.dx-scheduler-cell-sizes-vertical {
  height: 2.3338vh !important;
}

.dx-scheduler-time-panel-cell {
  border: none;
}

.dx-scheduler-date-table-row:nth-child(4n + 1) {
  border-top: 3px solid #25374699;
}
.dx-scheduler-date-table-row:nth-child(4n + 2) {
  border-top: hidden !important;
}
.dx-scheduler-date-table-row:nth-child(4n + 4) {
  border-top: hidden !important;
}

.dx-template-wrapper {
  overflow: hidden !important;
}

.dx-scrollable-container {
  overflow-y: hidden !important;
}

.dx-overlay-content {
  max-width: 100vw !important;
}

.dx-scheduler-header {
  display: none !important;
}

.dx-scheduler-header-panel-container {
  border-top: 1px solid #ebebeb;
}


::-webkit-scrollbar {
  width: 10px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #f1f1f1; 
}
 
/* Handle */
::-webkit-scrollbar-thumb {
  background: #888; 
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #555; 
}

.time-cell {
  font-size: 9px;
}

.dx-scrollable-native.dx-scrollable-native-ios .dx-scrollable-content {
  min-height: auto;
}

</style>