<template>
  <nav class="navbar navbar-expand-sm navbar-dark bg-primary">
    <div class="container-fluid">
      <span class="navbar-brand"
        ><img src="/img/logo.png" alt="ACT Property"
      /></span>
      <button
        class="navbar-toggler"
        type="button"
        data-toggle="collapse"
        data-target="#navbarSupportedContent"
        aria-controls="navbarSupportedContent"
        aria-expanded="false"
        aria-label="Toggle navigation"
      >
        <span class="navbar-toggler-icon"></span>
      </button>

      <div
        class="collapse navbar-collapse"
        id="navbarSupportedContent"
        v-if="signedIn"
      >
        <ul
          v-if="isSysAdmin || isAdministrator || isCustomer"
          class="navbar-nav mr-auto"
        >
          <li class="nav-item">
            <div v-if="isOnDiaryPages()" class="nav-link" style="cursor: pointer;" @click="openReportsPage"
                >Reports</div>
            <router-link v-else class="nav-link" :to="getReportsUrl()"
              >Reports</router-link
            >
          </li>

          <div v-if="isBooking || isQCUser || isBookingManagement" class="btn-group">
            <button
              type="button"
              class="btn btn-outline dropdown-toggle nav-link"
              data-toggle="dropdown"
              aria-haspopup="true"
              aria-expanded="false"
            >
              Diary
            </button>
            <div class="dropdown-menu">
              <div class="dropdown-item" style="cursor: pointer;" @click="openCalendarInNewTab"
                >Diary</div>
              <div class="dropdown-item" style="cursor: pointer;" @click="openDiaryFormInNewTab"
                >Booking Form</div>
              <router-link class="dropdown-item" to="/diarymanagementfilters"
                >Management Filters</router-link
              >
            </div>
          </div>
          <li v-if="isAdministrator" class="nav-item">
            <router-link class="nav-link" to="/propertyvisitmanagements"
              >PV Managements</router-link
            >
          </li>
          <!-- <li v-if="isAdministrator" class="nav-item">
            <router-link class="nav-link" to="/pvbookings"
              >PV Bookings</router-link
            >
          </li> -->

          <!--div v-if="isQCUser" class="btn-group">
            <button
              type="button"
              class="btn btn-outline dropdown-toggle nav-link"
              data-toggle="dropdown"
              aria-haspopup="true"
              aria-expanded="false"
            >
              QC
            </button>
            <div class="dropdown-menu">
              <router-link class="dropdown-item" style="cursor: pointer;" to="/qc/summary"
                >Summary</router-link>
              <router-link class="dropdown-item" style="cursor: pointer;" to="/qc/allocate"
                >Allocate</router-link>
              <router-link class="dropdown-item" style="cursor: pointer;" to="/qc/membersummary"
                >Member Summary</router-link
              >
            </div>
          </!--div-->

          <li v-if="isAdministrator" class="nav-item">
            <router-link class="nav-link" to="/customerlist"
              >Customers</router-link
            >
          </li>
          <li v-if="isAdministrator" class="nav-item">
            <router-link class="nav-link" to="/inspectorlist"
              >Inspectors</router-link
            >
          </li>
          <li v-if="isSysAdmin" class="btn-group">
            <button
              type="button"
              class="btn btn-outline dropdown-toggle nav-link"
              data-toggle="dropdown"
              aria-haspopup="true"
              aria-expanded="false"
            >
              Dictionary
            </button>
            <div class="dropdown-menu">
              <router-link class="dropdown-item" to="/dictionary">Dictionary</router-link>
              <router-link class="dropdown-item" to="/conversionmatrix">Conversion Matrix</router-link>
            </div>
          </li>
          <li v-else-if="isAdministrator" class="btn-group">
            <router-link class="nav-link" to="/dictionary">Dictionary</router-link>
          </li>

          <div v-if="isAdministrator" class="btn-group">
            <button
              type="button"
              class="btn btn-outline dropdown-toggle nav-link"
              data-toggle="dropdown"
              aria-haspopup="true"
              aria-expanded="false"
            >
              Designers
            </button>
            <div class="dropdown-menu">
              <router-link
                class="dropdown-item"
                to="/customerdictionary/property visit"
                >Mid Term Designer</router-link
              >
              <router-link
                class="dropdown-item"
                v-for="reporttype in reporttypeswithdictionary"
                :key="reporttype.slug"
                :to="`/customerdictionary/${reporttype.slug}`"
                >{{ reporttype.displayname }} Designer</router-link
              >
            </div>
          </div>

          <div v-if="isAdministrator" class="btn-group">
            <button
              type="button"
              class="btn btn-outline dropdown-toggle nav-link"
              data-toggle="dropdown"
              aria-haspopup="true"
              aria-expanded="false"
            >
              Templates
            </button>
            <div class="dropdown-menu">
              <router-link class="dropdown-item" to="/emailtemplates"
                >Email Templates</router-link
              >
              <router-link class="dropdown-item" to="/smstemplates"
                >SMS Templates</router-link
              >
            </div>
          </div>

          <li v-if="isSysAdmin" class="nav-item">
            <router-link class="nav-link" to="/remotesupport"
              >Remote Support</router-link
            ></li>

          <li v-if="isSysAdmin" class="nav-item">
            <router-link class="nav-link" to="/sysadmin"
              >System Admin</router-link
            >
          </li>
        </ul>
        <ul v-if="isDataentry && !isMobileDiaryUser" class="navbar-nav mr-auto">
          <li class="nav-item">
            <router-link class="nav-link" :to="getReportsUrl()"
              >Reports</router-link
            >
          </li>
        </ul>
        <ul class="navbar-nav ml-auto">
          <li class="nav-item">
            <span class="nav-link text-light">
              {{ email }}
            </span>
          </li>
          <li class="nav-item" v-if="currentOffice">
            <span class="nav-link text-light">
              {{ currentOffice.name }}
            </span>
          </li>
          <li class="nav-item">
            <a class="nav-link" href="/sign-out" @click.prevent="signOut()">
              Sign out
            </a>
          </li>
        </ul>
      </div>
    </div>
  </nav>
</template>

<script lang="ts" setup>
import { computed, inject, onMounted, onBeforeUnmount, onUnmounted, ref, watch } from 'vue';
import { useRoute, useRouter } from 'vue-router';
import Auth from "@aws-amplify/auth";
import { useStore } from 'vuex';
import moment from "moment-timezone";
import { useToast } from "vue-toastification";
import { Office, Dictionary, DictionaryReportType } from "@/models";
import Ably from "ably";

const route = useRoute();
const router = useRouter();

// State, Getters, Mutations, and Actions from Vuex
const store = useStore();
const email = computed(() => store.getters['auth/email']);
const isAdministrator = computed(() => store.getters['auth/isAdministrator']);
const isSysAdmin = computed(() => store.getters['auth/isSysAdmin']);
const isCustomer = computed(() => store.getters['auth/isCustomer']);
const isDataentry = computed(() => store.getters['auth/isDataentry']);
const isDiaryUser = computed(() => store.getters['auth/isDiaryUser']);
const isQCUser = computed(() => store.getters['auth/isQCUser']);
const isBookingManagement = computed(() => store.getters['auth/isBookingManagement']);
const signedIn = computed(() => store.getters['auth/signedIn']);
const isBooking = computed(() => store.getters['auth/isBooking']);
const currentOffice = computed((): Office => store.getters['offices/currentOffice']);
const dictionary = computed((): Dictionary => store.getters['dictionary/current']);
const reportsState = computed(() => store.state.reports);

const isMobileDiaryUser = computed(() => screen.width <= 760 && isDiaryUser);

// Injects
const realtime: Ably.Realtime = inject('realtime');
const actProperty: any = inject('actProperty');

// Refs
const diaryFormOpenConfirm = ref(false);
const channel: Ably.Types.RealtimeChannelCallbacks = realtime.channels.get('diary');
const deviceid = actProperty.getDeviceId();

// Functions converted to Composition API style
const setCognitoUser = (user: any) => store.commit('auth/setCognitoUser', user);

const signOut = async () => {
  try {
    router.push({ name: "home" });
    if (
      !store.getters["customers/hasUnsavedChanges"] &&
      !store.getters["dictionary/hasUnsavedChanges"] &&
      !store.getters["reports/hasUnsavedChanges"]
    ) {
      await Auth.signOut();
      setCognitoUser(null);
      router.push({ name: "sign-in" });
    }
  } catch (err: any) {
    actProperty.displayError(err);
  }
};

const getReportsUrl = () => {
  let url = isDataentry.value ? "/dereports" : "/reports";
  if (reportsState.value.month) {
    url += `?month=${encodeURIComponent(reportsState.value.month)}`;
  } else if (reportsState.value.search) {
    url += `?search=${encodeURIComponent(reportsState.value.search)}`;
  }
  return url;
};

const reporttypeswithdictionary = computed(() => {
  return dictionary.value?.reporttypes?.filter((t) => t.dictionaryflag === true);
});

const openCalendarInNewTab = () => {
  window.open("/scheduler/" + moment().format("DD-MM-YYYY"));
};

const openDiaryFormInNewTab = () => {
  diaryFormOpenConfirm.value = false;
  channel.publish('diaryFormOpenCheck', { deviceid: deviceid });
  setTimeout(() => {
    if (!diaryFormOpenConfirm.value) window.open("/diary");
  }, 1000);
};

const isOnDiaryPages = () => {
  return route.fullPath.includes('diary') || route.fullPath.includes('scheduler');
};

const openReportsPage = () => {
  window.open("/reports");
};

onMounted(() => {
  channel.subscribe('diaryFormOpenConfirm', (message: any) => {
    if (message.data.deviceid != deviceid) return;
    diaryFormOpenConfirm.value = true;
  });
});

onBeforeUnmount(() => {
  channel.unsubscribe('diaryFormOpenConfirm');
});

</script>


<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
.navbar-brand {
  img {
    max-height: 2rem;
    height: auto;
  }
}
</style>
