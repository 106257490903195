/**
 * Getters
 *
 * Vuex allows us to define "getters" in the store.
 * - You can think of them as computed properties for stores.
 * - Like computed properties, a getter's result is cached based on its dependencies,
 *   and will only re-evaluate when some of its dependencies have changed.
 */
import _ from "lodash";
import { GetterTree } from "vuex";
import { RootState } from "../types";
import { PvBookingsState } from "./types";
import {
  Booking,
  Customer,
} from "@/models";

export const getters: GetterTree<PvBookingsState, RootState> = {
  booking: (state: PvBookingsState): Booking => state.booking,
  list: (state: PvBookingsState): Booking[] => state.list,
  currentdate: (state: PvBookingsState): Date => state.currentdate,
  customer: (state: PvBookingsState): Customer => state.booking.customer,
  customerlist: (state: PvBookingsState): Customer[] => state.customerlist,
};
