import _, { ValueIteratee } from "lodash";
import _pick from "lodash/pick";
import moment from "moment-timezone";

import { API, Logger } from "aws-amplify";

import axios from "axios";

import { ActionTree, ActionContext } from "vuex";
import { RootState } from "../types";
import { PvBookingsState } from "./types";
import { Storage } from "aws-amplify";
import { s3Origin } from "@/utilities";

import {
  datetimeToUTC,
  sortInspectorList,
  differenceInSeconds,
  bookingdateformat,
} from "../../utilities";

import {
  Booking,
  GetaddressResponse,
  Email,
  SMS,
  SMSLogs,
  EmailLogs,
  Inspector,
  Location,
  DistanceMatrixResponse,
  TravelTime,
  Report,
  Auditlog,
  Auditlogvaluechange,
  Job,
  Attachments,
  Customer,
  Contact,
  Qcmember,
} from "@/models";
import inspectors from "../inspectors";
import { SearchAddressResponse } from "@/models/google/SearchAddressResponse";
import { event } from "jquery";
import { Qcmemberdailytarget } from "@/models/qcmemberdailytarget.model";

// Using API from aws-amplify was resulting in a "no current user" error
// So had to take authentication off the endpoint in cloudformation
// And use axios to make the request without authorisation so it doesn't try
// to use aws-amplify to return a user
// There may be an option to turn this feature off but I couldn't find it
const unAuthenticatedApiRequest = async (
  awsApi: any,
  endpoint: string,
  type = "get",
  opts: any
) => {
  const awsApiConfig = awsApi.configure({});
  // grab the AWS api domain from the config
  const restApiUrl = awsApiConfig.endpoints[0].endpoint + endpoint;
  // console.log('restApiUrl', restApiUrl)
  const options = {
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
    },
    ...opts,
  };

  if (type === "post") {
    return axios.post(restApiUrl, options);
  }
  return axios.get(restApiUrl, options);
};

const unAuthenticatedApiPostRequest = async (awsApi: any, endpoint: string) => {
  return unAuthenticatedApiRequest(awsApi, endpoint, "post", null);
};

const unAuthenticatedApiPutRequest = async (
  awsApi: any,
  endpoint: string,
  payload: any
) => {
  const awsApiConfig = awsApi.configure({});
  // grab the AWS api domain from the config
  const restApiUrl = awsApiConfig.endpoints[0].endpoint + endpoint;
  // console.log('restApiUrl', restApiUrl)
  const options = {
    headers: {
      "Content-Type": "application/json",
    },
  };
  return axios.put(restApiUrl, payload.body);
};

/**
 * Download an attachment (PDF/ZIP) using a Job (polling).
 *
 * @param {string} generateEndpoint Hit first, returns a Job
 * @param {string} downloadEndpoint Hit second, starts processing
 *
 * @returns {Promise} Attachment S3 Key
 */
const bookingAttachmentJob = async (
  generateEndpoint: string,
  downloadEndpoint: string
) => {
  // The first API call returns a Job
  // return API.post('RestAPI', generateEndpoint, null)
  // use unauthenticated post request as the endpoint should require no authorisation
  return unAuthenticatedApiPostRequest(API, generateEndpoint)
    .then((response) => new Job(response.data))
    .then((job: Job) => poll(job, downloadEndpoint))
    .then((payload) => {
      if (payload.file === undefined) {
        throw new Error("Missing Attachment Key");
      }
      return payload.file;
    });
};

/**
 * Polls start/poll endpoints to see if a Job has completed.
 *
 * @param {string} job      Job
 * @param {string} startUrl Hit once to start Job
 *
 * @returns {Promise} Payload object
 */
const poll = async (job: Job, startUrl: string) => {
  // should put these constants into .env
  const POLL_TIMEOUT = 900; // seconds
  const POLL_INTERVAL = 2; // seconds

  const { id } = job;

  let count = 0;
  let errorMessage = "";
  unAuthenticatedApiPostRequest(API, `${startUrl}?token=${id}`).catch((err) => {
    // catch errors returned by pdf download
    // err.reponse will be undefined if API Gateway timeout which we will ignore
    // otherwise we want to return it so we can show back to the user
    if (err.response === undefined) {
      console.log("Ignore API Gateway timeout");
    } else {
      errorMessage = err.response.data.message;
    }
  });

  // Keep polling until the job is complete, or time limit is reached
  while (count * POLL_INTERVAL < POLL_TIMEOUT) {
    count++;

    if (errorMessage) {
      // for PDFs this error is likely to be from the Pug template
      throw new Error(errorMessage);
    }

    // const pollResult = await API.get('RestAPI', `/jobs/${id}`, null);
    const response = await unAuthenticatedApiRequest(
      API,
      `/jobs/${id}`,
      "get",
      null
    );
    const pollResult = response.data;

    console.log(`Poll attempt #${count}`, pollResult);

    if (pollResult.completed === true) {
      if (pollResult.payload === undefined) {
        throw new Error("No Job payload");
      }
      return pollResult.payload;
    }

    await waitForSeconds(POLL_INTERVAL);
  }

  throw new Error("AWS Lambda time limit exceeded.");
};

const updateBookingInStore = (
  store: ActionContext<PvBookingsState, any>,
  booking: Booking
): Booking[] => {
  if (
    store.state.booking &&
    store.state.booking.id &&
    store.state.booking.id === booking.id
  ) {
    store.state.booking = booking;
  }

  // Get Booking index
  const bookingIndex = store.state.list.findIndex(
    (r) => r._uuid === booking._uuid
  );

  if (bookingIndex == -1) {
    return store.state.list;
  }

  // Update Booking in Bookings list
  return [
    ...store.state.list.slice(0, bookingIndex),
    booking,
    ...store.state.list.slice(bookingIndex + 1),
  ];
};

/**
 * https://stackoverflow.com/questions/33289726/combination-of-async-function-await-settimeout
 *
 * @param seconds - number of seconds to wait
 */
const waitForSeconds = (seconds: number) => {
  return new Promise((resolve) => setTimeout(resolve, seconds * 1000));
};

/**
 * Actions
 *
 * Actions are similar to mutations, the differences being that:
 * - Instead of mutating the state, actions commit mutations.
 * - Actions can contain arbitrary asynchronous operations.
 * - Actions are triggered with the store.dispatch method e.g. `store.dispatch('getSettings')`
 */
export const actions: ActionTree<PvBookingsState, RootState> = {
  /**
   * Get Customer list with given starting character
   *
   * - Used for select tag options
   * @param {CustomersState} store
   * @returns Company Name options
   */
  async getCustomers(
    store: ActionContext<PvBookingsState, any>
  ) {
    const options = {
      queryStringParameters: {
        fieldlist: "_id,company_name,branch_name"
      },
    };

    store.commit("app/addRequest", "getCustomers", { root: true });
    return API.get("RestAPI", `/customers`, options)
      .then((response) =>
        response.map((x: Partial<Customer>) => new Customer(x))
      )
      .then((customers: Customer[]) => {
        store.commit("setCustomers", customers);
        return customers;
      })
      .finally(() =>
        store.commit("app/removeRequest", "getCustomers", { root: true })
      );
  },
    /**
   * Add multiple Bookings
   *
   * @param {DiaryState} store
   * @param bookings: Booking[]
   * @returns Booking[]
   */
    async addPvBookings(
      store: ActionContext<PvBookingsState, any>,
      bookings: Booking[]
    ) {
      store.commit("app/addRequest", "addPvBookings", { root: true });
      let jsonBody: any = [];
      bookings.forEach((booking: Booking) => jsonBody.push(booking.toJSON()));
      return API.post("RestAPI", `/pvbookings`, { body: jsonBody })
        .then((response) => response.map((x: Partial<Booking>) => new Booking(x)))
        .finally(() =>
          store.commit("app/removeRequest", "addPvBookings", { root: true })
        );
    }
};
