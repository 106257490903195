<template>
  <div @focusout="closeDropdown">
    <DxDateBox
      v-show="changesdatepvmid === pvm.id"
      class="form-control height-43"
      :value="scheduleddate"
      display-format="dd/MM/yyyy"
      type="date"
      picker-type="calendar"
      apply-value-mode="instantly"
      :calendarOptions="{ firstDayOfWeek: 1 }"
      @value-changed="setScheduledDate(pvm.id, $event.value)"
      :ref="'scheduledDateSelect-' + pvm.id"
    />
    <div
      v-show="changesdatepvmid !== pvm.id"
      :class="{ 'transparent-text': !pvm.scheduleddate }"
      @click="changeSDateReport()"
    >
      {{
        pvm.scheduleddate
          ? actProperty.formatDateForDisplay(pvm.scheduleddate)
          : "Select"
      }}
    </div>
  </div>
</template>
<script lang="ts" setup>
import { ref, computed, toRefs, defineEmits, defineProps, inject } from "vue";
import { useStore } from "vuex";
import { Booking } from "@/models";
import moment from "moment-timezone";
import { DxDateBox } from "devextreme-vue/date-box";
const store = useStore();
const actProperty: any = inject("actProperty");
const propertyvisitmanagement = computed(
  () => store.getters["propertyvisitmanagement/getBookingsData"]
);
const props = defineProps<{
  pvm: Booking;
  changesdatepvmid: string;
}>();
const emit = defineEmits(["schedulatedatepvmselected"]);
const scheduledDateSelect = ref<{ [key: string]: HTMLElement | undefined }>({});
const setBookingsData = (data: Booking[]) =>
  store.commit("propertyvisitmanagement/setBookings", data);
const { pvm, changesdatepvmid } = toRefs(props);
const changeSDateReport = () => {
  emit("schedulatedatepvmselected", pvm.value.id);

  const dropdown = scheduledDateSelect.value[`descheduleddate-${pvm.value.id}`] as any;
  if (dropdown) {
    dropdown.activate();
  }
};
const setScheduledDate = async (id: string, $event: any) => {  
  if($event === 'Invalid date' || $event === null) return;
  const pmIndex = propertyvisitmanagement.value.findIndex(
    (r: Booking) => r.id === id
  );
  propertyvisitmanagement.value[pmIndex].scheduleddate =
    moment($event).format("YYYY-MM-DD[T]HH:mm");
  setBookingsData(propertyvisitmanagement.value);
  pvm.value.id = id;
  emit("schedulatedatepvmselected", "");
};
const closeDropdown = () => {
  emit("schedulatedatepvmselected", "");
};
const scheduleddate = ref("");
</script>

<style scoped lang="scss">
.emptystatus {
  color: rgb(255, 255, 255);
  background-color: tomato;
}
.transparent-text {
  color: transparent;
}
</style>

<style lang="scss">
.statusdtopdown > .multiselect__content-wrapper {
  width: fit-content;
}
</style>
