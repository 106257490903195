<template>
  <div @focusout="closeDropdown">
    <singleselect-text
      v-model="inspector"
      track-by="slug"
      label="name"
      :options="inspectorlist"
      :allow-empty="true"
      :taggable="false"
      class="statusdtopdown"
      v-show="changeinspectorpvmid === pvm.id"
      :ref="'dynamicInspectorRefs-' + pvm.id"
      @select="onSelectInspector(pvm.id, $event)"
    >
      <template #clear v-if="pvm.id">
        <i
          @mousedown.prevent.stop="onSelectInspector(pvm.id, $event)"
          class="multiselect__clear fa fa-times"
          aria-label="Clear"
        ></i>
      </template>
    </singleselect-text>
    <div
      v-show="changeinspectorpvmid != pvm.id"
      :class="{ 'transparent-text': !pvm.inspector.id }"
      @click="changeStatusReport()"
    >
      {{ pvm.inspector.id ? pvm.inspector.name : "Select Dropdown" }}
    </div>
  </div>
</template>

<script lang="ts" setup>
import { ref, computed, toRefs, defineEmits, defineProps } from "vue";
import { useStore } from "vuex";
import { Inspector, Booking, SelectOption } from "@/models";

const store = useStore();
const inspectorlist = computed(() => store.getters["diary/inspectorlist"]);
const propertyvisitmanagement = computed(
  () => store.getters["propertyvisitmanagement/getBookingsData"]
);
const props = defineProps<{
  pvm: Booking;
  changeinspectorpvmid: string;
}>();
const emit = defineEmits(["inspectorpvmselected"]);
const dynamicInspectorRefs = ref<{ [key: string]: HTMLElement | undefined }>({});
const setBookingsData = (data: Booking[]) =>
  store.commit("propertyvisitmanagement/setBookings", data);
const { pvm, changeinspectorpvmid } = toRefs(props);
const changeStatusReport = () => {
  emit("inspectorpvmselected", pvm.value.id);

  const dropdown = dynamicInspectorRefs.value[`dynamicInspectorRefs-${pvm.value.id}`] as any;
  if (dropdown) {
    dropdown.activate();
  }
};
const onSelectInspector = async (id: string | undefined, val: string) => {
  const pmIndex = propertyvisitmanagement.value.findIndex(
    (r: Booking) => r.id === id
  );
  propertyvisitmanagement.value[pmIndex].inspector = val;
  setBookingsData(propertyvisitmanagement.value);
  pvm.value.id = id;
  emit("inspectorpvmselected", "");
};
const closeDropdown = () => {
  emit("inspectorpvmselected", "");
};
const inspector = ref<SelectOption>(new SelectOption());
</script>

<style scoped lang="scss">
.emptystatus {
  color: rgb(255, 255, 255);
  background-color: tomato;
}
.transparent-text {
  color: transparent;
}
</style>

<style lang="scss">
.statusdtopdown > .multiselect__content-wrapper {
  width: fit-content;
}
</style>
